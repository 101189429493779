import { useState } from 'react';
import { FaBars, FaMinus, FaPlus } from 'react-icons/fa';
import { CgClose } from 'react-icons/cg';
import styled from 'styled-components';
import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import { WEB_NAME } from 'src/constants/layout';
import { useRouter } from 'next/router';
import { APP_IMG } from 'src/constants';
const NavMobile = ({ data }) => {
  const [toggle, setToggle] = useState(false);
  const router = useRouter();
  const [expand, setExpand] = useState({ status: false, key: null });
  function handleToggle(_key, _index) {
    switch (_key) {
      case 'close':
        setToggle(false);
        setExpand({ key: null, status: false });
        break;
      case 'open':
        setToggle(!toggle);
        break;
      case 'menu':
        setToggle(true);
        setExpand({
          key: _index,
          status: expand?.key === _index ? !expand.status : true
        });
        break;
      default:
        setToggle(false);
        break;
    }
  }

  return (
    <>
      <NavMobileStyle>
        <div className='nav-mobile'>
          {toggle || expand.status ? (
            <CgClose onClick={() => handleToggle('close')} />
          ) : (
            <FaBars
              onClick={() => {
                handleToggle('open');
              }}
            />
          )}
        </div>
        <nav className={`nav-dropdown-mobile ${toggle ? 'visible' : 'invisible'}`}>
          <button onClick={() => handleToggle('close')} className='close-btn'>
            <CgClose />
          </button>
          {/* ---------------------------------- MENU ---------------------------------- */}
          <ul className={'nav-menu'}>
            <li className='active-menu'>
              <div className='d-flex'>
                <a
                  title='หน้าแรก'
                  href='/'
                  //! DATALAYER
                  onClick={() =>
                    sendDataLayerGlobal({
                      type: DATALAYER_TYPE.TRACK_POSITION,
                      router: router?.pathname,
                      position: 'on:header',
                      section: 'header',
                      data: {
                        title: 'หน้าแรก',
                        heading: 'หน้าแรก'
                      }
                    })
                  }
                >
                  <div className='list-menu'>
                    <div className='item-menu'>
                      <span className='menu'>หน้าแรก</span>
                    </div>
                  </div>
                </a>
              </div>
            </li>
            {(toggle || expand.status) &&
              Array.isArray(data) &&
              data.length > 0 &&
              data.slice(0, 3).map((menu, index) => (
                <li key={index} className='active-menu'>
                  <div className='d-flex'>
                    <a
                      title={menu?.nameTh}
                      href={`/${menu?.link}`}
                      //! DATALAYER
                      onClick={() =>
                        sendDataLayerGlobal({
                          type: DATALAYER_TYPE.TRACK_POSITION,
                          router: router?.pathname,
                          position: 'on:header',
                          section: 'header',
                          data: {
                            title: menu?.nameTh,
                            heading: `${menu?.nameTh}`
                          }
                        })
                      }
                    >
                      <div className='list-menu'>
                        <div className='item-menu'>
                          <span className='menu'>{menu?.nameTh}</span>
                        </div>
                      </div>
                    </a>
                    <div className='drop-item' onClick={() => handleToggle('menu', index)}>
                      {/* ------------------------------- Icon Expand ------------------------------ */}
                      {Array.isArray(menu?.sub) && menu?.sub.length > 0 && (expand?.key === index && expand?.status ? <FaMinus /> : <FaPlus />)}
                    </div>
                  </div>
                  {/* --------------------------------- SUBMENU -------------------------------- */}
                  {expand?.key === index && expand?.status && Array.isArray(menu.sub) && (
                    <ul className='nav-sub-menu'>
                      {menu.sub.map((subMenu, index) => (
                        <li key={index} className='active-sub-menu'>
                          <a
                            title={subMenu?.nameTh}
                            href={subMenu?.link}
                            //! DATALAYER
                            onClick={() =>
                              sendDataLayerGlobal({
                                type: DATALAYER_TYPE.TRACK_POSITION,
                                router: router?.pathname,
                                position: 'on:header',
                                section: 'header',
                                data: {
                                  title: subMenu?.nameTh,
                                  heading: `${menu?.nameTh}:${subMenu?.nameTh}`
                                }
                              })
                            }
                          >
                            <div className='list-sub-menu'>
                              <span className='sub-menu'> {subMenu?.nameTh} </span>
                            </div>
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
          </ul>
          <a
            aria-label='logo-img'
            title='logo'
            className='img-nav'
            href='/'
            // onClick={() =>
            //   sendDataLayerGlobal({
            //     //! DATALAYER
            //     key: KEY_EVENT.TRACK_EVENT,
            //     event: KEY_EVENT.HEADER_LOGO,
            //     data: {
            //       name: WEB_NAME
            //     }
            //   })
            // }
          >
            <img className='logo-img' src={`${APP_IMG}/images/cropped-khobsanam-logo-1.png`} loading='lazy' alt='logo-mobile' title='logo-mobile' width='100%' height='100%' />
          </a>
        </nav>
      </NavMobileStyle>
    </>
  );
};

const NavMobileStyle = styled.div`
  /* start nav mobile */
  ul.nav-menu,
  ul.nav-sub-menu {
    list-style: none;
  }
  .nav-mobile {
    cursor: pointer;
    svg {
      font-size: 24px;
      color: #00ff57;
    }
  }
  .invisible {
    visibility: hidden !important;
  }
  nav.nav-dropdown-mobile {
    position: fixed;
    top: 0;
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    background-color: #010b2b;
    height: 100vh;
    width: 100%;
    left: 0;
    overflow-y: scroll;
    z-index: 5;
    /* ------------------------------- MOBILE : Navigation Menu -------------------------------- */

    .close-btn {
      /* background-color: #00ff57; */
      position: absolute;
      background: none;
      top: 10px;
      right: 10px;
      border: none;
      padding: 8px 10px;
      font-size: 16px;
      cursor: pointer;
      svg {
        vertical-align: middle;
        color: #00ff57;
        font-size: 30px;
      }
    }

    .img-nav {
      margin: 50px auto 0 auto;
      img {
        width: 150px;
        height: auto;
      }
    }
  }
  .logo-img {
    opacity: 0.6;
  }
  /* end nav mobile */
`;

export default NavMobile;
